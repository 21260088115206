<template>
  <div class="genralInformationComponent">
    <v-form ref="form" v-model="valid">
      <v-row>
        <!-- First Col -->
        <v-col cols="12" md="6">
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Full Name In English") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.en.name"
                solo
                :rules="[
                  validationRules.required,
                  validationRules.maxLength400
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Full Name In Arabic") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.ar.name"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength400,
                  CheckArabicCharactersOnly(generalInfoObj.ar.name)
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- item -->
          <!-- code -->
          <!-- <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>Code:</strong>
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :rules="[validationRules.required]"
                v-model="generalInfoObj.code"
                solo
              ></v-text-field>

              <div class="error-sec" v-if="validation_errors.code">
                <p class="error-txt red--text">{{ validation_errors.code }}</p>
              </div>
            </v-col>
          </v-row> -->

          <!-- Row Date of birth  -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date Of Birth") }}:</strong>
              </p>
            </v-col>
            <v-col cols="12" md="6" style="margin-top: -10px">
              <v-radio-group
                v-model="generalInfoObj.date_of_birth_type"
                :rules="[validationRules.required]"
                row
              >
                <v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio>
                <v-radio
                  value="gregorian"
                  :label="$i18n.t('Gregorian')"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date Of Birth") }}:</strong>
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :rules="[validationRules.required]"
                v-show="generalInfoObj.date_of_birth_type == 'gregorian'"
                autocomplete="off"
                append-icon="calendar_today"
                id="date_of_birth_gregorian"
                v-model.trim="generalInfoObj.date_of_birth_gregorian"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="date_of_birth_hijri"
                :rules="[validationRules.required]"
                v-show="generalInfoObj.date_of_birth_type == 'hijri'"
                append-icon="calendar_today"
                autocomplete="off"
                v-model.lazy="generalInfoObj.date_of_birth_hijri"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Email") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="generalInfoObj.email" solo></v-text-field>
            </v-col>
          </v-row>
          <!-- item -->
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Nationality") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :items="nationality"
                @change="hideLabel = true"
                :rules="[validationRules.required]"
                item-text="name"
                item-value="id"
                v-model="generalInfoObj.nationality_id"
                solo
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <!-- item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Place Of birth") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.place_of_birth"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Gender") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-radio-group
                v-model="generalInfoObj.gendar"
                :rules="[validationRules.required]"
                row
              >
                <v-radio value="Male" :label="$i18n.t('Male')"></v-radio
                ><v-radio value="Female" :label="$i18n.t('Female')"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Mobile Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :items="codes"
                :rules="[validationRules.required]"
                required
                @change="hideLabel = true"
                item-text="title"
                item-value="value"
                v-model="generalInfoObj.mobile_country_code_id"
                solo
              >
                <template slot="selection" slot-scope="data">
                  <v-flex xs3>
                    <v-avatar size="25">
                      <img :src="data.item.icon" />
                    </v-avatar>
                  </v-flex>
                  <v-flex class="ml-3">{{ data.item.title }}</v-flex>
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-avatar
                    style="width: 20px; min-width: 20px; height: 20px"
                  >
                    <img :src="data.item.icon" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.title"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>

              <v-text-field
                :rules="[
                  validationRules.required,
                  mobileRule(
                    generalInfoObj.mobile_country_code_id,
                    generalInfoObj.mobile
                  )
                ]"
                v-model.trim="generalInfoObj.mobile"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("ID Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.citizen_id_number"
                :rules="[validationRules.required, validationRules.maxLength25]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.citizen_id_source"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-radio-group
                v-model="generalInfoObj.citizen_id_expiry_date_type"
                :rules="[validationRules.required]"
                row
              >
                <v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio
                ><v-radio
                  value="gregorian"
                  :label="$i18n.t('Gregorian')"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Date") }} :</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                id="citizen_id_expiry_date_gregorian"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="
                  generalInfoObj.citizen_id_expiry_date_type == 'gregorian'
                "
                autocomplete="off"
                v-model.trim="generalInfoObj.citizen_id_expiry_date_gregorian"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="citizen_id_expiry_date_hijri"
                :rules="[validationRules.required]"
                append-icon="calendar_today"
                v-show="generalInfoObj.citizen_id_expiry_date_type == 'hijri'"
                autocomplete="off"
                v-model.lazy="generalInfoObj.citizen_id_expiry_date_hijri"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Passport Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.citizen_passport_number"
                :rules="[validationRules.required, validationRules.maxLength25]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.citizen_passport_source"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-radio-group
                v-model="generalInfoObj.citizen_passport_expiry_date_type"
                :rules="[validationRules.required]"
                row
              >
                <v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio>
                <v-radio
                  value="gregorian"
                  :label="$i18n.t('Gregorian')"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Date") }} :</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                id="citizen_passport_expiry_date_gregorian"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="
                  generalInfoObj.citizen_passport_expiry_date_type ==
                    'gregorian'
                "
                autocomplete="off"
                v-model.trim="
                  generalInfoObj.citizen_passport_expiry_date_gregorian
                "
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="citizen_passport_expiry_date_hijri"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="
                  generalInfoObj.citizen_passport_expiry_date_type == 'hijri'
                "
                autocomplete="off"
                v-model.lazy="generalInfoObj.citizen_passport_expiry_date_hijri"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row v-if="generalInfoObj.citizen_social_insurance_number">
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Social Insurance") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.citizen_social_insurance_number"
                :rules="[validationRules.maxLength25]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>

        <!-- end Col -->

        <!-- Second Col -->
        <v-col cols="12" md="6">
          <!-- item -->
          <div v-if="generalInfoObj.nationality_id != 187">
            <v-row>
              <v-col cols="12" md="6">
                <p>
                  <strong>{{ $t("Sponsor Name") }}:</strong>
                </p>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength400
                  ]"
                  v-model="generalInfoObj.sponsor_name"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- item -->

            <!-- item -->
            <v-row>
              <v-col cols="12" md="6">
                <p>
                  <strong>{{ $t("Relationship") }}:</strong>
                </p>
              </v-col>

              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="[
                    $i18n.t('Brother'),
                    $i18n.t('Father'),
                    $i18n.t('Husband'),
                    $i18n.t('Other')
                  ]"
                  @change="hideLabel = true"
                  v-model="generalInfoObj.sponsor_relationship"
                  :rules="[validationRules.required]"
                  solo
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- item -->
            <!-- item -->
            <v-row v-if="generalInfoObj.sponsor_relationship == 'Other'">
              <v-col cols="12" md="6">
                <p>
                  <strong>{{ $t("Job") }}:</strong>
                </p>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="generalInfoObj.sponsor_job"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength100
                  ]"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- item -->
            <!-- item -->
            <v-row v-if="generalInfoObj.sponsor_relationship != 'Other'">
              <v-col cols="12" md="6">
                <p>
                  <strong>{{ $t("ID Number") }}:</strong>
                </p>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="generalInfoObj.sponsor_id_number"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength25
                  ]"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- item -->
            <!-- item -->
            <v-row v-if="generalInfoObj.sponsor_relationship != 'Other'">
              <v-col cols="12" md="6">
                <p>
                  <strong>{{ $t("Source") }}:</strong>
                </p>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="generalInfoObj.sponsor_source"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength25
                  ]"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- item -->
            <!-- item -->
            <v-row v-if="generalInfoObj.sponsor_relationship != 'Other'">
              <v-col cols="12" md="6">
                <p>
                  <strong>{{ $t("Expiry") }}:</strong>
                </p>
              </v-col>

              <v-col cols="12" md="6">
                <v-radio-group
                  v-model="generalInfoObj.sponsor_expiry_date_type"
                  :rules="[
                    CheckIfInputRequired(
                      generalInfoObj.sponsor_expiry_date_type
                    )
                  ]"
                  row
                >
                  <v-radio
                    value="hijri"
                    :disabled="generalInfoObj.sponsor_relationship == 'Other'"
                    :label="$i18n.t('Hijri')"
                  ></v-radio
                  ><v-radio
                    value="gregorian"
                    :disabled="generalInfoObj.sponsor_relationship == 'Other'"
                    :label="$i18n.t('Gregorian')"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <!-- item -->

            <!-- item -->
            <v-row v-if="generalInfoObj.sponsor_relationship != 'Other'">
              <v-col cols="12" md="6">
                <p>
                  <strong>{{ $t("Expiry Date") }}:</strong>
                </p>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  id="sponsor_expiry_date_gregorian"
                  :rules="[validationRules.required]"
                  append-icon="calendar_today"
                  v-show="
                    generalInfoObj.sponsor_expiry_date_type == 'gregorian'
                  "
                  autocomplete="off"
                  v-model.trim="generalInfoObj.sponsor_expiry_date_gregorian"
                  solo
                  @keydown.prevent
                  @paste.prevent
                  @drop.prevent
                ></v-text-field>
                <v-text-field
                  id="sponsor_expiry_date_hijri"
                  :rules="[validationRules.required]"
                  v-show="generalInfoObj.sponsor_expiry_date_type == 'hijri'"
                  append-icon="calendar_today"
                  autocomplete="off"
                  v-model.lazy="generalInfoObj.sponsor_expiry_date_hijri"
                  solo
                  @keydown.prevent
                  @paste.prevent
                  @drop.prevent
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- /item -->

            <!-- item -->
            <v-row v-if="generalInfoObj.sponsor_relationship != 'Other'">
              <v-col cols="12" md="6">
                <p>
                  <strong>{{ $t("Source Notes") }}:</strong>
                </p>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="generalInfoObj.sponsor_notes"
                  :disabled="generalInfoObj.nationality_id == 187"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- /item -->
          </div>
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Country") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :items="nationality"
                :rules="[validationRules.required]"
                @change="hideLabel = true"
                item-text="name"
                item-value="id"
                v-model="generalInfoObj.address_country_id"
                solo
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("City") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.address_city"
                :rules="[validationRules.required, validationRules.maxLength50]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Street Name") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.address_street"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength150
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Building Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.address_building_number"
                :rules="[validationRules.required, validationRules.maxLength20]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Additional Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.address_additional_number"
                :rules="[validationRules.required, validationRules.maxLength20]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Zip Code") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="generalInfoObj.address_zip_code"
                :rules="[validationRules.required, validationRules.maxLength10]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>
        <!-- end Col -->
      </v-row>
    </v-form>
    <v-row>
      <v-spacer></v-spacer>

      <v-btn class="modal-btn-cancel" style="margin: 10px" @click="close">{{
        $t("Close")
      }}</v-btn>
      <v-btn
        class="modal-btn-save"
        style="margin: 10px"
        @click="updateGenralInformation"
        >{{ $t("Save") }}</v-btn
      >
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");
// import ACL from "../../acl";
import { validationMixin } from "../../../../mixins/validationMixin";
// import moment from "moment";
export default {
  mixins: [validationMixin],
  name: "editGenralInformationComponent",
  props: ["generalInfoObj", "editGeneralInfo"],
  data() {
    return {
      valid: true,
      validation_errors: [],
      codes: [],
      nationality: []
    };
  },
  watch: {
    "generalInfoObj.date_of_birth_gregorian": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.generalInfoObj.date_of_birth_hijri = m.format("iDD/iMM/iYYYY");
      } else {
        this.generalInfoObj.date_of_birth_hijri = "";
      }
    },
    "generalInfoObj.date_of_birth_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.generalInfoObj.date_of_birth_gregorian = h.format("DD/MM/YYYY");
      } else {
        this.generalInfoObj.date_of_birth_gregorian = "";
      }
    },

    "generalInfoObj.sponsor_expiry_date_gregorian": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.generalInfoObj.sponsor_expiry_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.generalInfoObj.sponsor_expiry_date_hijri = "";
      }
    },
    "generalInfoObj.sponsor_expiry_date_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.generalInfoObj.sponsor_expiry_date_gregorian = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.generalInfoObj.sponsor_expiry_date_gregorian = "";
      }
    },

    "generalInfoObj.citizen_id_expiry_date_gregorian": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.generalInfoObj.citizen_id_expiry_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.generalInfoObj.citizen_id_expiry_date_hijri = "";
      }
    },
    "generalInfoObj.citizen_id_expiry_date_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.generalInfoObj.citizen_id_expiry_date_gregorian = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.generalInfoObj.citizen_id_expiry_date_gregorian = "";
      }
    },

    "generalInfoObj.citizen_passport_expiry_date_gregorian": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.generalInfoObj.citizen_passport_expiry_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.generalInfoObj.citizen_passport_expiry_date_hijri = "";
      }
    },
    "generalInfoObj.citizen_passport_expiry_date_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.generalInfoObj.citizen_passport_expiry_date_gregorian = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.generalInfoObj.citizen_passport_expiry_date_gregorian = "";
      }
    }
  },
  methods: {
    CheckIfInputRequired(v) {
      // console.log(this.generalInfoObj.sponsor_relationship);
      if (
        this.generalInfoObj.sponsor_relationship != "Other" ||
        this.generalInfoObj.sponsor_relationship != ""
      ) {
        if (v.length <= 0) {
          // console.log("khlkhk");
          return this.$i18n.t("This field is required");
        } else return true;
      }
    },
    close() {
      this.editGeneralInfo = false;
      this.$emit("childToParent", false);
    },
    getCountries() {
      axios.get(this.getApiUrl + "/getCountries").then(response => {
        this.nationality = response.data.data;
      });
    },
    activateDatePicker() {
      let _this = this;

      $(document).ready(function() {
        $(
          "#date_of_birth_gregorian,#sponsor_expiry_date_gregorian,#citizen_id_expiry_date_gregorian,#citizen_passport_expiry_date_gregorian"
        ).calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              if ($(this).attr("id") == "date_of_birth_gregorian") {
                _this.generalInfoObj.date_of_birth_gregorian = dateH;
              }
              if ($(this).attr("id") == "sponsor_expiry_date_gregorian") {
                _this.generalInfoObj.sponsor_expiry_date_gregorian = dateH;
              }
              if ($(this).attr("id") == "citizen_id_expiry_date_gregorian") {
                _this.generalInfoObj.citizen_id_expiry_date_gregorian = dateH;
              }
              if (
                $(this).attr("id") == "citizen_passport_expiry_date_gregorian"
              ) {
                _this.generalInfoObj.citizen_passport_expiry_date_gregorian = dateH;
              }
            } else {
              if ($(this).attr("id") == "date_of_birth_gregorian") {
                _this.generalInfoObj.date_of_birth_gregorian = "";
              }
              if ($(this).attr("id") == "sponsor_expiry_date_gregorian") {
                _this.generalInfoObj.sponsor_expiry_date_gregorian = "";
              }
              if ($(this).attr("id") == "citizen_id_expiry_date_gregorian") {
                _this.generalInfoObj.citizen_id_expiry_date_gregorian = "";
              }
              if (
                $(this).attr("id") == "citizen_passport_expiry_date_gregorian"
              ) {
                _this.generalInfoObj.citizen_passport_expiry_date_gregorian =
                  "";
              }
            }
          }
        });
        $(document).ready(function() {
          $(
            "#date_of_birth_hijri,#sponsor_expiry_date_hijri,#citizen_id_expiry_date_hijri,#citizen_passport_expiry_date_hijri"
          ).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function(date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                if ($(this).attr("id") == "date_of_birth_hijri") {
                  _this.generalInfoObj.date_of_birth_hijri = dateH;
                }
                if ($(this).attr("id") == "sponsor_expiry_date_hijri") {
                  _this.generalInfoObj.sponsor_expiry_date_hijri = dateH;
                }
                if ($(this).attr("id") == "citizen_id_expiry_date_hijri") {
                  _this.generalInfoObj.citizen_id_expiry_date_hijri = dateH;
                }
                if (
                  $(this).attr("id") == "citizen_passport_expiry_date_hijri"
                ) {
                  _this.generalInfoObj.citizen_passport_expiry_date_hijri = dateH;
                }
                // _this.generalInfoObj.date_of_birth_hijri = dateH;
              } else {
                if ($(this).attr("id") == "date_of_birth_hijri") {
                  _this.generalInfoObj.date_of_birth_hijri = "";
                }
                if ($(this).attr("id") == "sponsor_expiry_date_hijri") {
                  _this.generalInfoObj.sponsor_expiry_date_hijri = "";
                }
                if ($(this).attr("id") == "citizen_id_expiry_date_hijri") {
                  _this.generalInfoObj.citizen_id_expiry_date_hijri = "";
                }
                if (
                  $(this).attr("id") == "citizen_passport_expiry_date_hijri"
                ) {
                  _this.generalInfoObj.citizen_passport_expiry_date_hijri = "";
                }
              }
            }
          });
        });
      });
    },
    updateGenralInformation() {
      if (
        this.generalInfoObj.nationality_id == 187 ||
        this.generalInfoObj.nationality_id === 187
      ) {
        this.generalInfoObj.sponsor_name = "";
        this.generalInfoObj.sponsor_relationship = "";
        this.generalInfoObj.sponsor_job = "";
        this.generalInfoObj.sponsor_id_numbe = "";
        this.generalInfoObj.sponsor_source = "";
        // this.generalInfoObj.sponsor_expiry_date_type = "";
        this.generalInfoObj.sponsor_expiry_date_gregorian = "";
        this.generalInfoObj.sponsor_expiry_date_hijri = "";
        this.generalInfoObj.sponsor_notes = "";
      }
      if (this.valid == false) {
        this.validate();
      } else {
        axios
          .post(
            this.getApiUrl +
              "/employee/update/" +
              this.$router.currentRoute.params.id +
              "?general",
            this.generalInfoObj,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            if (response.data.status.error == false) {
              // this.changeTab(1);
              this.close();
            }
          });
      }
    }
  },
  mounted() {
    axios.get(this.getApiUrl + "/getCodes").then(response => {
      this.codes = response.data.data;
    });

    this.getCountries();
    this.activateDatePicker();
  }
};
</script>
