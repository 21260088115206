<template>
  <div class="genralInformationComponent">
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="12" md="7">
          <p class="value">{{ $t("Basic Salary") }}</p>
        </v-col>

        <v-col cols="12" md="5">
          <v-text-field
            :rules="[
              validationRules.required,
              validationRules.floatNumberNotZero,
            ]"
            v-model="SalaryObject.basic_salary"
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="form-item">
        <v-col cols="12" md="12">
          <div>
            <p class="value">{{ $t("Allowance(s)") }}</p>
          </div>
          <v-autocomplete
            :items="allowancesItems"
            @change="hideLabel = true && onSelect(SalaryObject.allowaIds)"
            item-text="name"
            item-value="id"
            multiple
            v-model="SalaryObject.allowaIds"
            chips
            :rules="[validationRules.required]"
            solo
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <div v-if="SalaryObject.allowances.length > 0">
        <v-row class="form-item">
          <v-col
            v-for="(allowance, index) in SalaryObject.allowances"
            :key="index"
            cols="12"
            md="3"
          >
            <div>
              <span class="allowance-title">{{ allowance.name }}</span>
              <v-row>
                <v-col cols="12" md="7">
                  <v-text-field
                    class="allowance-input"
                    v-if="allowance.account_type == 'amount'"
                    v-model="allowance.value"
                    :rules="[
                      validationRules.required,
                      validationRules.numericNotZero,
                      validationRules.maxLength10,
                    ]"
                  ></v-text-field>
                  <v-text-field
                    class="allowance-input"
                    v-else
                    v-model="allowance.value"
                    :rules="[
                      validationRules.required,
                      validationRules.percentage,
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <span
                    v-if="allowance.account_type == 'amount'"
                    class="type"
                    >{{ $t("SAR") }}</span
                  >
                  <span v-else class="type">%</span>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-row>
      <v-spacer></v-spacer>

      <v-btn class="modal-btn-cancel" style="margin: 10px" @click="close">{{
        $t("Close")
      }}</v-btn>
      <v-btn
        class="modal-btn-save"
        style="margin: 10px"
        @click="updateCourses"
        >{{ $t("Save") }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

import { validationMixin } from "../../../../mixins/validationMixin";
export default {
  mixins: [validationMixin],
  name: "EditSalary",
  props: ["SalaryObject", "editSalaryInfo", "allowancesItems"],
  data() {
    return {
      valid: false,
      // allowancesItems: []
    };
  },
  methods: {
    onSelect(object) {
      let types = [];
      object.forEach((element) => {
        console.log(element);
        let indexid = this.allowancesItems.findIndex((p) => p.id == element);
        let name = this.allowancesItems[indexid].name;
        let type = this.allowancesItems[indexid].type;
        let value = this.allowancesItems[indexid].value;
        let account_type = this.allowancesItems[indexid].account_type;
        let allowance_id = this.allowancesItems[indexid].id;
        // console.log(type);
        types.push({
          allowance_id: allowance_id,
          name: name,
          type: type,
          value: value,
          account_type: account_type,
        });
      });
      this.SalaryObject.allowances = types;
    },
    // getAllowances() {
    //   axios
    //     .get(
    //       this.getApiUrl +
    //         "/employee/getAllowances?nationality_id=" +
    //         this.SalaryObject.nationality_id,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token
    //           //the token is a variable which holds the token
    //         }
    //       }
    //     )
    //     .then(response => {
    //       this.allowancesItems = response.data.data;
    //       // console.log(response);
    //     });
    // },
    close() {
      this.editSalaryInfo = false;
      this.$emit("childToParent", false);
    },
    updateCourses() {
      if (this.valid == false) {
        this.validate();
      } else {
        axios
          .post(
            this.getApiUrl +
              "/employee/update/" +
              this.$router.currentRoute.params.id,
            this.SalaryObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              // this.changeTab(1);
              this.close();
            }
          });
      }
    },
  },
  mounted() {
    // this.getAllowances();
  },
};
</script>

<style lang="scss" scoped>
// @import "../../../styles/_variables.scss";
// @import "../../../styles/_forms.scss";

.allowance-title {
  padding: 10px;
  background: #7297ff;
  display: block;
  color: #fff;
  text-align: center;
  border-radius: 35px;
}
.allowance-value {
  display: block;
  text-align: center;
}
.type {
  padding: 12px 0;
  display: block;
}
p.value {
  font-weight: 700;
}
</style>
