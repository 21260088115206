<template>
  <div class="genralInformationComponent">
    <v-form v-model="valid" ref="form">
      <v-row>
        <!-- First Col -->
        <v-col cols="12" md="8">
          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Job Title") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                :items="jobs"
                @change="hideLabel = true && select(jobOpject.job_id)"
                item-text="name"
                item-value="id"
                v-model="jobOpject.job_id"
                :rules="[validationRules.required]"
                solo
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item Joining Date:-->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Joining Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="4">
              <p>{{ jobOpject.contract_date_signed }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Experience Years Inside KSA") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="jobOpject.experience_years_ksa"
                :rules="[validationRules.required]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Experience Years Outside KSA") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="jobOpject.experience_years_outside_ksa"
                :rules="[validationRules.required]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>
        <!-- end Col -->
      </v-row>
      <hr />
      <v-row>
        <v-col cols="12" md="6">
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Education Level") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="jobOpject.education_level"
                :rules="[validationRules.required]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="jobOpject.education_level_source"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100,
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-radio-group
                v-model="jobOpject.education_level_date_type"
                :rules="[validationRules.required]"
                row
              >
                <v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio>
                <v-radio
                  value="gregorian"
                  :label="$i18n.t('Gregorian')"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong> {{ $t("Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                id="education_level_date_gregorian"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="jobOpject.education_level_date_type == 'gregorian'"
                autocomplete="off"
                v-model.trim="jobOpject.education_level_date_gregorian"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="education_level_date_hijri"
                :rules="[validationRules.required]"
                append-icon="calendar_today"
                v-show="jobOpject.education_level_date_type == 'hijri'"
                autocomplete="off"
                v-model.lazy="jobOpject.education_level_date_hijri"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>

        <!-- secod Col -->
        <v-col cols="12" md="6">
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Qualification Level") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="jobOpject.education_qualification"
                :rules="[validationRules.required, validationRules.maxLength25]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="jobOpject.education_qualification_source"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100,
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-radio-group
                v-model="jobOpject.education_qualification_date_type"
                :rules="[validationRules.required]"
                row
              >
                <v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio>
                <v-radio
                  value="gregorian"
                  :label="$i18n.t('Gregorian')"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                id="education_qualification_date_gregorian"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="
                  jobOpject.education_qualification_date_type == 'gregorian'
                "
                autocomplete="off"
                v-model.trim="jobOpject.education_qualification_date_gregorian"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="education_qualification_date_hijri"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="jobOpject.education_qualification_date_type == 'hijri'"
                autocomplete="off"
                v-model.lazy="jobOpject.education_qualification_date_hijri"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-spacer></v-spacer>

      <v-btn class="modal-btn-cancel" style="margin: 10px" @click="close">{{
        $t("Close")
      }}</v-btn>
      <v-btn
        class="modal-btn-save"
        style="margin: 10px"
        @click="updateJobDetails"
        >{{ $t("Save") }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");
// import ACL from "../../acl";
import { validationMixin } from "../../../../mixins/validationMixin";
// import moment from "moment";
export default {
  mixins: [validationMixin],
  name: "editJobDetailsComponent",
  props: ["jobOpject"],
  data() {
    return {
      valid: false,
      jobs: [],
    };
  },
  watch: {
    "jobOpject.education_level_date_gregorian": function (v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.jobOpject.education_level_date_hijri = m.format("iDD/iMM/iYYYY");
      } else {
        this.jobOpject.education_level_date_hijri = "";
      }
    },
    "jobOpject.education_level_date_hijri": function (v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.jobOpject.education_level_date_gregorian = h.format("DD/MM/YYYY");
      } else {
        this.jobOpject.education_level_date_gregorian = "";
      }
    },

    "jobOpject.education_qualification_date_gregorian": function (v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.jobOpject.education_qualification_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.jobOpject.education_qualification_date_hijri = "";
      }
    },
    "jobOpject.education_qualification_date_hijri": function (v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.jobOpject.education_qualification_date_gregorian = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.jobOpject.education_qualification_date_gregorian = "";
      }
    },
  },
  methods: {
    close() {
      //   this.editJobInfo = false;
      this.$emit("childToParent", false);
    },
    updateJobDetails() {
      if (this.valid == false) {
        this.validate();
      } else {
        axios
          .post(
            this.getApiUrl +
              "/employee/update/" +
              this.$router.currentRoute.params.id,
            this.jobOpject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              // this.changeTab(1);
              this.close();
            }
          });
      }
    },
    activateDatePicker() {
      let _this = this;

      $(document).ready(function () {
        $(
          "#education_level_date_gregorian,#education_qualification_date_gregorian"
        ).calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              if ($(this).attr("id") == "education_level_date_gregorian") {
                _this.jobOpject.education_level_date_gregorian = dateH;
              }
              if (
                $(this).attr("id") == "education_qualification_date_gregorian"
              ) {
                _this.jobOpject.education_qualification_date_gregorian = dateH;
              }
            } else {
              if ($(this).attr("id") == "date_of_birth_gregorian") {
                _this.jobOpject.education_level_date_gregorian = "";
              }
              if (
                $(this).attr("id") == "education_qualification_date_gregorian"
              ) {
                _this.jobOpject.education_qualification_date_gregorian = "";
              }
            }
          },
        });
        $(document).ready(function () {
          $(
            "#education_level_date_hijri,#education_qualification_date_hijri"
          ).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                if ($(this).attr("id") == "education_level_date_hijri") {
                  _this.jobOpject.education_level_date_hijri = dateH;
                }
                if (
                  $(this).attr("id") == "education_qualification_date_hijri"
                ) {
                  _this.jobOpject.education_qualification_date_hijri = dateH;
                }

                // _this.generalInfoObj.date_of_birth_hijri = dateH;
              } else {
                if ($(this).attr("id") == "education_level_date_hijri") {
                  _this.jobOpject.education_level_date_hijri = "";
                }
                if (
                  $(this).attr("id") == "education_qualification_date_hijri"
                ) {
                  _this.jobOpject.education_qualification_date_hijri = "";
                }
              }
            },
          });
        });
      });
    },
    getJobs() {
      axios
        .get(this.getApiUrl + "/employee/allJobs", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.jobs = response.data.data;
          // console.log(response);
        });
    },
  },
  mounted: function () {
    this.getJobs();
    this.activateDatePicker();
  },
};
</script>
