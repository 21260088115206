<template>
  <div class="genralInformationComponent">
    <v-row v-if="coursesObject.length > 0 && !editCourseInfo">
      <!-- col loop-->
      <v-col
        cols="12"
        md="6"
        style="border: 2px solid #ddd; border-radius: 5px"
        v-for="(item, index) in coursesObject"
        :key="index"
      >
        <!-- item -->
        <v-row>
          <v-col cols="12" md="4">
            <p>
              <strong>{{ $t("Course Name") }}:</strong>
            </p>
          </v-col>

          <v-col cols="12" md="5">
            <p>{{ item.course_name }}</p>
          </v-col>
        </v-row>
        <!-- /item -->

        <!-- item -->
        <v-row>
          <v-col cols="12" md="4">
            <p>
              <strong>{{ $t("Date") }}:</strong>
            </p>
          </v-col>

          <v-col cols="12" md="5">
            <p>{{ item.course_date }}</p>
          </v-col>
        </v-row>
        <!-- /item -->
        <!-- item -->
        <v-row>
          <v-col cols="12" md="4">
            <p>
              <strong>{{ $t("Date Hijri") }}:</strong>
            </p>
          </v-col>

          <v-col cols="12" md="5">
            <p>{{ item.course_date_hijri }}</p>
          </v-col>
        </v-row>
        <!-- /item -->

        <!-- item -->
        <v-row>
          <v-col cols="12" md="4">
            <p>
              <strong>{{ $t("Duration") }}:</strong>
            </p>
          </v-col>

          <v-col cols="12" md="5">
            <p>{{ item.course_duration }}</p>
          </v-col>
        </v-row>
        <!-- /item -->

        <!-- item -->
        <v-row>
          <v-col cols="12" md="4">
            <p>
              <strong>{{ $t("Source") }}:</strong>
            </p>
          </v-col>

          <v-col cols="12" md="5">
            <p>{{ item.course_source }}</p>
          </v-col>
        </v-row>
        <!-- /item -->

        <!-- item -->
        <v-row>
          <v-col cols="12" md="4">
            <p>
              <strong>{{ $t("Grade") }}:</strong>
            </p>
          </v-col>

          <v-col cols="12" md="5">
            <p>{{ item.course_grade }}</p>
          </v-col>
        </v-row>
        <!-- /item -->

        <!-- item -->
        <v-row>
          <v-col cols="12" md="4">
            <p>
              <strong>{{ $t("Notes") }}:</strong>
            </p>
          </v-col>

          <v-col cols="12" md="5">
            <p>{{ item.course_notes }}</p>
          </v-col>
        </v-row>
        <!-- /item -->
      </v-col>
      <!-- end Col -->
    </v-row>
    <v-row v-if="coursesObject.length == 0 && !editCourseInfo">
      <v-col cols="12" md="6">
        <p class="value">{{ $t("You don't have any courses") }}</p>
      </v-col>
    </v-row>
    <CoursesComponent
      :coursesObject="coursesObject"
      :editCourseInfo="editCourseInfo"
      v-on:childToParent="onChildClick"
      v-if="editCourseInfo"
    />
  </div>
</template>

<script>
import axios from "axios";
import CoursesComponent from "../EditEmployee/CoursesComponent";
export default {
  name: "courses",
  props: ["editCourseInfo"],
  data() {
    return {
      coursesObject: [],
    };
  },
  components: { CoursesComponent },
  methods: {
    onChildClick(val) {
      this.getData();
      this.editCourseInfo = val;
      this.$emit("CourseParent", false);
    },
    getData() {
      axios
        .get(
          this.getApiUrl +
            "/employee/getJEmployeeCourses/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          this.coursesObject = response.data.data;
        });
    },
  },
  mounted: function () {
    this.getData();
  },
};
</script>
