var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"genralInformationComponent"},[(!_vm.editSalaryInfo)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t("Basic Salary")))])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('p',[_vm._v(_vm._s(_vm.SalaryObject.basic_salary)+" "+_vm._s(_vm.$t("SAR")))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":""}},[_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t("Allowance(s)")))])]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._l((_vm.SalaryObject.allowances),function(allowance,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(allowance.name)+" "),(
                  allowance.type == 'deduction' &&
                  allowance.account_type == 'percentage'
                )?_c('span',[_vm._v("("+_vm._s(allowance.value)+" %) ")]):(
                  allowance.type == 'allowance' &&
                  allowance.account_type == 'percentage'
                )?_c('span',[_vm._v(_vm._s(allowance.value)+" % ")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(allowance.type == 'deduction')?_c('span',[_vm._v("("+_vm._s(allowance.amount_value)+")")]):_c('span',[_vm._v(_vm._s(allowance.amount_value))]),_vm._v(" "+_vm._s(_vm.$t("SAR")))])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t("Net Salary")))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" "+_vm._s(_vm.SalaryObject.net_salary)+" "+_vm._s(_vm.$t("SAR")))])],1)],2)],1)],1):_vm._e(),(_vm.editSalaryInfo)?_c('SalaryComponent',{attrs:{"editSalaryInfo":_vm.editSalaryInfo,"SalaryObject":_vm.SalaryObject,"allowancesItems":_vm.allowancesItems},on:{"childToParent":_vm.onChildClick}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }