<template>
  <div class="genralInformationComponent">
    <div v-if="!editSalaryInfo">
      <v-row>
        <v-col cols="12" md="7">
          <p class="value">{{ $t("Basic Salary") }}</p>
        </v-col>

        <v-col cols="12" md="5">
          <p>{{ SalaryObject.basic_salary }} {{ $t("SAR") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="">
          <p class="value">{{ $t("Allowance(s)") }}</p>
        </v-col>

        <v-col cols="12" md="9">
          <!-- allowance loop -->
          <v-row
            v-for="(allowance, index) in SalaryObject.allowances"
            :key="index"
          >
            <v-col cols="12" md="6"
              ><p class="value">
                {{ allowance.name }}
                <span
                  v-if="
                    allowance.type == 'deduction' &&
                    allowance.account_type == 'percentage'
                  "
                  >({{ allowance.value }} %)
                </span>
                <span
                  v-else-if="
                    allowance.type == 'allowance' &&
                    allowance.account_type == 'percentage'
                  "
                  >{{ allowance.value }} %
                </span>
              </p></v-col
            >
            <v-col cols="12" md="6">
              <span v-if="allowance.type == 'deduction'"
                >({{ allowance.amount_value }})</span
              >
              <span v-else>{{ allowance.amount_value }}</span>
              {{ $t("SAR") }}</v-col
            >
          </v-row>
          <!-- net Salary -->
          <v-row>
            <v-col cols="12" md="6"
              ><p class="value">{{ $t("Net Salary") }}</p></v-col
            >
            <v-col cols="12" md="6">
              {{ SalaryObject.net_salary }} {{ $t("SAR") }}</v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </div>
    <SalaryComponent
      :editSalaryInfo="editSalaryInfo"
      :SalaryObject="SalaryObject"
      v-on:childToParent="onChildClick"
      :allowancesItems="allowancesItems"
      v-if="editSalaryInfo"
    />
  </div>
</template>

<script>
import axios from "axios";
import SalaryComponent from "../EditEmployee/SalaryComponent";
export default {
  name: "salaryComponent",
  props: ["editSalaryInfo", "allowancesItems"],
  data() {
    return {
      SalaryObject: {},
    };
  },
  components: { SalaryComponent },
  methods: {
    onChildClick(val) {
      this.getData();
      this.editSalaryInfo = val;
      this.$emit("SalaryParent", false);
    },
    getData() {
      axios
        .get(
          this.getApiUrl +
            "/employee/getJEmployeeSalary/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          this.SalaryObject = response.data.data;
        });
    },
  },
  mounted: function () {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
// @import "../../../styles/_variables.scss";
// @import "../../../styles/_forms.scss";

.allowance-title {
  padding: 10px;
  background: #7297ff;
  display: block;
  color: #fff;
  text-align: center;
  border-radius: 35px;
}
.allowance-value {
  display: block;
  text-align: center;
}
.type {
  padding: 12px 0;
  display: block;
}
p.value {
  font-weight: 700;
}
</style>
