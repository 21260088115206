<template>
  <div class="genralInformationComponent">
    <div v-if="!editJobInfo">
      <v-row>
        <!-- First Col -->
        <v-col cols="12" md="8">
          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Job Title") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="4">
              <p>{{ jobDetailsObject.job }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Joining Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ jobDetailsObject.contract_date_signed }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Experience Years Inside KSA") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="4">
              <p>{{ jobDetailsObject.experience_years_ksa }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Experience Years Outside KSA") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="4">
              <p>{{ jobDetailsObject.experience_years_outside_ksa }}</p>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>
        <!-- end Col -->
      </v-row>
      <hr />
      <v-row>
        <v-col cols="12" md="6">
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Education Level") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ jobDetailsObject.education_level }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ jobDetailsObject.education_level_source }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ jobDetailsObject.education_level_date_gregorian }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date Hijri") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ jobDetailsObject.education_level_date_hijri }}</p>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>

        <!-- secod Col -->
        <v-col cols="12" md="6">
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Qualification Level") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ jobDetailsObject.education_qualification }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ jobDetailsObject.education_qualification_source }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>
                {{ jobDetailsObject.education_qualification_date_gregorian }}
              </p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date Hijri") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ jobDetailsObject.education_qualification_date_hijri }}</p>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>
      </v-row>
    </div>
    <jobDetailsComponent
      :jobOpject="jobDetailsObject"
      v-on:childToParent="onChildClick"
      v-if="editJobInfo"
    />
  </div>
</template>

<script>
import axios from "axios";
import jobDetailsComponent from "../EditEmployee/jobDetailsComponent";
export default {
  name: "jobDetails",
  props: ["editJobInfo"],
  data() {
    return {
      jobDetailsObject: {
        job_id: "",
        job: "",
        goining_date: "",
        experience_years_ksa: "",
        experience_years_outside_ksa: "",
        education_level: "",
        education_level_source: "",
        education_level_date_gregorian: "",
        education_level_date_hijri: "",
        education_qualification: "",
        education_qualification_source: "",
        education_qualification_date_gregorian: "",
        education_qualification_date_hijri: "",
      },
    };
  },
  components: {
    jobDetailsComponent,
  },
  methods: {
    onChildClick(val) {
      this.getJobInformationData();
      // this.editJobInfo = val;
      this.$emit("JobParent", val);
    },
    getJobInformationData() {
      axios
        .get(
          this.getApiUrl +
            "/employee/getJobDetailse/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.jobDetailsObject = response.data.data;
          this.jobDetailsObject = {
            job_id: response.data.data.job_id,
            job: response.data.data.job,

            experience_years_ksa: response.data.data.experience_years_ksa,
            experience_years_outside_ksa:
              response.data.data.experience_years_outside_ksa,
            education_level: response.data.data.education_level,
            education_level_source: response.data.data.education_level_source,
            education_level_date_gregorian:
              response.data.data.education_level_date_gregorian,
            education_level_date_hijri:
              response.data.data.education_level_date_hijri,
            education_qualification: response.data.data.education_qualification,
            education_qualification_source:
              response.data.data.education_qualification_source,
            education_qualification_date_gregorian:
              response.data.data.education_qualification_date_gregorian,
            education_qualification_date_hijri:
              response.data.data.education_qualification_date_hijri,
            education_qualification_date_type: "hijri",
            education_level_date_type: "hijri",
            contract_date_signed_type: "hijri",
            contract_date_signed: response.data.data.contract_date_signed,
            contract_date_signed_hijri:
              response.data.data.contract_date_signed_hijri,
          };
        });
    },
  },
  mounted: function () {
    this.getJobInformationData();
  },
};
</script>
