<template>
  <div id="user_profile">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row style="padding-top: 40px; min-height: 130vh">
      <v-col cols="12" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text style="padding: 0 !important">
            <v-row
              class="lighten-5"
              style="margin: 0 !important; padding: 0 !important"
              justify="center"
            >
              <v-col
                cols="12"
                sm="3"
                class="text-center main-color-background"
                v-if="!editable"
              >
                <div class="attachment">
                  <v-avatar size="100">
                    <v-img v-if="emp != null && emp != ''" :src="emp.image" alt>
                      <v-row
                        style="
                          background-color: rgba(13, 11, 8, 0.5);
                          height: 50%;
                          margin-top: 60%;
                        "
                      >
                        <v-col class="pl-3 py-0 mt-n4" cols="2">
                          <v-file-input @change="readFiles" chips hide-input>
                          </v-file-input>
                        </v-col>
                        <v-col cols="9" class="pb-6">
                          <p style="font-size: 10px; color: #e1e1e1">
                            {{ $t("Edit Image") }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-img>
                    <v-img
                      v-if="emp.image == null"
                      src="../../../assets/avatar.png"
                    >
                      <v-row
                        style="
                          background-color: rgba(13, 11, 8, 0.5);
                          height: 50%;
                          margin-top: 60%;
                        "
                      >
                        <v-col class="pl-3 py-0 mt-n4" cols="2">
                          <v-file-input @change="readFiles" chips hide-input>
                          </v-file-input>
                        </v-col>
                        <v-col cols="9" class="pb-6">
                          <p style="font-size: 10px; color: #e1e1e1">
                            {{ $t("Edit Image") }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-img>
                  </v-avatar>
                </div>
                <v-spacer></v-spacer>
                <p class="mb-0 error--text" v-if="imageValidation">
                  {{ imageValidation }}
                </p>
                <center>{{ emp.name }}</center>
                <center style="color: green">{{ emp.job }}</center>
                <div
                  class="list-btns"
                  style="width: 90%; margin: auto; text-align: left:margin-top: 35px;"
                >
                  <v-btn
                    rounded
                    :class="generalInformation ? 'TabBtnActive' : 'TabBtn'"
                    @click="openGeneralInformation"
                    class="d-block"
                    style="width: 100% !important"
                  >
                    <v-row>
                      <v-col cols="12" md="2"> <v-icon> person </v-icon></v-col>
                      <v-col cols="12" md="8">{{
                        $t("General Information")
                      }}</v-col>
                      <v-col cols="12" md="2" class="text-right"
                        ><v-icon
                          @click="ChangeEditGeneralInfo('general')"
                          v-if="!editGeneralInfo"
                          >edit</v-icon
                        ></v-col
                      >
                    </v-row>
                  </v-btn>

                  <v-btn
                    rounded
                    :class="jobDetails ? 'TabBtnActive' : 'TabBtn'"
                    @click="openjobDetails"
                    class="d-block"
                    style="width: 100% !important"
                  >
                    <v-row>
                      <v-col cols="12" md="2"> <v-icon> work </v-icon></v-col>
                      <v-col cols="12" md="8">{{ $t("Job Details") }}</v-col>
                      <v-col cols="12" md="2" class="text-right">
                        <v-icon
                          @click="ChangeEditGeneralInfo('job')"
                          v-if="!editJobInfo"
                          >edit</v-icon
                        ></v-col
                      >
                    </v-row>
                  </v-btn>

                  <v-btn
                    rounded
                    :class="courses ? 'TabBtnActive' : 'TabBtn'"
                    @click="openCourses"
                    class="d-block"
                    style="width: 100% !important"
                  >
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-icon> local_library </v-icon></v-col
                      >
                      <v-col cols="12" md="8">{{ $t("Courses") }}</v-col>
                      <v-col cols="12" md="2" class="text-right">
                        <v-icon
                          @click="ChangeEditGeneralInfo('course')"
                          v-if="!editCourseInfo"
                          >edit</v-icon
                        ></v-col
                      >
                    </v-row>
                  </v-btn>
                  <v-btn
                    rounded
                    :class="salary ? 'TabBtnActive' : 'TabBtn'"
                    @click="openSalary"
                    class="d-block"
                    style="width: 100% !important"
                  >
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-icon>attach_money </v-icon></v-col
                      >
                      <v-col cols="12" md="8">{{ $t("Salary") }}</v-col>
                      <v-col cols="12" md="2" class="text-right">
                        <v-icon
                          @click="ChangeEditGeneralInfo('salary')"
                          v-if="!editSalaryInfo"
                          >edit</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-btn>

                  <v-btn
                    rounded
                    :class="contract ? 'TabBtnActive' : 'TabBtn'"
                    @click="openContract"
                    class="d-block"
                    style="width: 100% !important"
                  >
                    <v-row>
                      <v-col cols="12" md="2">
                        <i class="fa fa-file"></i
                      ></v-col>
                      <v-col cols="12" md="8">{{ $t("Contract") }}</v-col>
                      <v-col cols="12" md="2" class="text-right">
                        <v-icon
                          @click="ChangeEditGeneralInfo('contract')"
                          v-if="!editContractInfo"
                          >edit</v-icon
                        ></v-col
                      >
                    </v-row>
                  </v-btn>
                  <v-btn
                    rounded
                    :class="userAccount ? 'TabBtnActive' : 'TabBtn'"
                    @click="openUserAccount"
                    class="d-block"
                  >
                    <v-row>
                      <v-col cols="12" md="2">
                        <i class="fa fa-address-card"></i
                      ></v-col>
                      <v-col cols="12" md="8"> {{ $t("User Account") }}</v-col>
                      <v-col cols="12" md="2" class="text-right">
                        <v-icon
                          @click="ChangeEditGeneralInfo('userAccount')"
                          v-if="!editUserAccount"
                          >edit</v-icon
                        ></v-col
                      >
                    </v-row>
                  </v-btn>
                  <v-btn
                    rounded
                    :to="viewAllRequestUrl"
                    :class="userAccount ? 'TabBtnActive' : 'TabBtn'"
                  >
                    <v-icon>remove_red_eye</v-icon>
                    <span class="ml-4">
                      {{ $t("View Requests") }}
                    </span>
                  </v-btn>
                </div>
              </v-col>
              <!-- contract -->
              <v-col
                cols="12"
                sm="9"
                id="info-div"
                class="info-div"
                v-if="contract"
              >
                <contractComponent
                  :editContractInfo="editContractInfo"
                  v-on:ContractParent="ContractParentClick"
                ></contractComponent>
              </v-col>
              <!-- end contract -->

              <!-- Salary -->
              <v-col
                cols="12"
                sm="9"
                id="info-div"
                class="info-div"
                v-if="salary"
              >
                <salaryComponent
                  :editSalaryInfo="editSalaryInfo"
                  :allowancesItems="allowancesItems"
                  v-on:SalaryParent="SalaryParentClick"
                ></salaryComponent>
              </v-col>
              <!-- End Salary -->

              <!-- Courses -->
              <v-col
                cols="12"
                sm="9"
                id="info-div"
                class="info-div"
                v-if="courses"
              >
                <coursesComponent
                  :editCourseInfo="editCourseInfo"
                  v-on:CourseParent="CourseParentClick"
                ></coursesComponent>
              </v-col>
              <!-- End Courses -->

              <!-- jobDetails -->
              <v-col
                cols="12"
                sm="9"
                id="info-div"
                class="info-div"
                v-if="jobDetails"
              >
                <v-row> </v-row>
                <jobDetailsComponent
                  :editJobInfo="editJobInfo"
                  v-on:JobParent="JobParentClick"
                ></jobDetailsComponent>
              </v-col>
              <!-- End jobDetails -->
              <!-- Genral Information  -->
              <v-col
                cols="12"
                sm="9"
                id="info-div"
                class="info-div"
                v-if="generalInformation"
              >
                <div class="viewData" v-if="!editable && generalInformation">
                  <!-- genral component -->
                  <genralInformationComponent
                    :editGeneralInfo="editGeneralInfo"
                    v-on:GeneralParent="GeneralParentClick"
                  ></genralInformationComponent>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="9"
                id="info-div"
                class="info-div"
                v-if="userAccount"
              >
                <div class="viewData" v-if="!editable && userAccount">
                  <!-- create user component -->
                  <!-- v-on:GeneralParent="GeneralParentClick" -->
                  <CreateUserAccount
                    :editUserAccount="editUserAccount"
                    v-on:showIconEdit="showIconEdit"
                  ></CreateUserAccount>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
// import ACL from "../../../acl";
// import { ProfileMixin } from "../../../mixins/ProfileMixin";
import genralInformationComponent from "../../../components/hr/Employee/viewEmployee/genralInformationComponent";
import jobDetailsComponent from "../../../components/hr/Employee/viewEmployee/jobDetailsComponent";
import coursesComponent from "../../../components/hr/Employee/viewEmployee/coursesComponent";
import salaryComponent from "../../../components/hr/Employee/viewEmployee/salaryComponent";
import contractComponent from "../../../components/hr/Employee/viewEmployee/contractComponent";
import CreateUserAccount from "../../../components/hr/Employee/viewEmployee/CreateUserAccount";

export default {
  name: "ShowEmployees",
  // mixins: [ProfileMixin],
  components: {
    genralInformationComponent,
    jobDetailsComponent,
    coursesComponent,
    salaryComponent,
    contractComponent,
    CreateUserAccount,
  },
  data() {
    return {
      imageValidation: "",
      editGeneralInfo: false,
      editJobInfo: false,
      editCourseInfo: false,
      editSalaryInfo: false,
      editContract: false,
      editContractInfo: false,
      editUserAccount: false,
      /** end notification table data */
      generalInformation: true,
      jobDetails: false,
      courses: false,
      salary: false,
      contract: false,
      userAccount: false,

      validation_errors_password: {},
      /**end password change */
      emp: "",
      profileDataEdit: {},
      error: false,
      successMsg: "",
      validation_errors: [],
      selectedFile: null,
      logo: "",
      logoError: "",
      otherLangSelected: false,
      submitted: false,
      backendUrl: this.$store.state.backendUrl,
      editable: false,
      nationalities: [],
      Codes: [],
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      roles: [],
      employeeId: "",
      viewAllRequestUrl: "",
      allowancesItems: [],
      // canEditRole: ACL.checkPermission("edit-users-role"),
    };
  },
  methods: {
    checkFileType(event) {
      if (
        event.type == "image/jpeg" ||
        event.type == "image/gif" ||
        event.type == "image/jpg" ||
        event.type == "image/tiff" ||
        event.type == "image/png"
      ) {
        return true;
      } else {
        return (this.imageValidation = "please enter a valid format");
      }
    },
    checkFileSize(event) {
      if (event.size >= 52428800) {
        this.imageValidation = "the image size shouldn't exceed 50M";
      } else {
        return true;
      }
    },
    readFiles(event) {
      this.imageValidation = "";
      if (
        this.checkFileType(event) === true &&
        this.checkFileSize(event) === true
      ) {
        const formData = new FormData();

        formData.append("image", event);
        axios
          .post(
            this.getApiUrl +
              "/employee/uploadEmployeeImage/" +
              this.$router.currentRoute.params.id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.emp.image = response.data.data.url;
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    getProfileImage() {
      axios
        .get(
          this.getApiUrl +
            "/employee/getEmployeeImage/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.emp = response.data.data;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    ContractParentClick(val) {
      this.editContractInfo = val;
    },
    SalaryParentClick(val) {
      this.editSalaryInfo = val;
    },
    CourseParentClick(val) {
      this.editCourseInfo = val;
    },
    JobParentClick(val) {
      this.editJobInfo = val;
    },
    GeneralParentClick(val) {
      this.editGeneralInfo = val;
    },
    showIconEdit(val) {
      this.editUserAccount = val;
    },
    getAllowances() {
      axios
        .get(
          this.getApiUrl +
            "/employee/getAllowances?nationality_id=" +
            this.emp.nationality_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.allowancesItems = response.data.data;
          // console.log(response);
        });
    },
    ChangeEditGeneralInfo(page) {
      if (page == "general") {
        this.editGeneralInfo = !this.editGeneralInfo;
      } else if (page == "job") {
        // this.openjobDetails();
        this.editJobInfo = !this.editJobInfo;
      } else if (page == "course") {
        this.editCourseInfo = !this.editCourseInfo;
      } else if (page == "salary") {
        this.getAllowances();
        this.editSalaryInfo = !this.editSalaryInfo;
      } else if (page == "contract") {
        this.editContractInfo = !this.editContractInfo;
      } else if (page == "userAccount") {
        this.editUserAccount = !this.editUserAccount;
      }
    },
    openGeneralInformation() {
      this.generalInformation = true;
      this.jobDetails = false;
      this.courses = false;
      this.salary = false;
      this.contract = false;
      this.userAccount = false;
    },
    openjobDetails() {
      this.generalInformation = false;
      this.jobDetails = true;
      this.courses = false;
      this.salary = false;
      this.contract = false;
      this.userAccount = false;
    },
    openCourses() {
      this.generalInformation = false;
      this.jobDetails = false;
      this.courses = true;
      this.salary = false;
      this.contract = false;
      this.userAccount = false;
    },
    openSalary() {
      this.generalInformation = false;
      this.jobDetails = false;
      this.courses = false;
      this.salary = true;
      this.contract = false;
      this.userAccount = false;
    },
    openContract() {
      this.generalInformation = false;
      this.jobDetails = false;
      this.courses = false;
      this.salary = false;
      this.contract = true;
      this.userAccount = false;
    },
    openUserAccount() {
      this.generalInformation = false;
      this.jobDetails = false;
      this.courses = false;
      this.salary = false;
      this.contract = false;
      this.userAccount = true;
    },
  },
  mounted: function() {
    this.employeeId = this.$router.currentRoute.params.id;
    this.viewAllRequestUrl = "/reviewrequest?empId=" + this.employeeId;
    this.getProfileImage();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";
.main-color-background {
  background-color: #e3e7f3;
}
.dark-color {
  color: #646464 !important;
}
.TabBtnActive {
  font-weight: bold;
  margin: 1px;
  background: rgba(255, 255, 255, 0.5) !important;
  // border-top: 2px solid #b9cbff !important;
  // border-bottom: 2px solid #b9cbff !important;
  color: #7297ff !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.TabBtn {
  margin: 1px;
  // border-top: 2px solid #bbbbbb !important;
  // border-bottom: 2px solid #bbbbbb !important;
  background: transparent !important;
  color: #bbbbbb !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}
.v-application p {
  margin-bottom: 0px !important;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.viewData {
  margin-left: 5px !important;
}
.editData {
  margin-left: 5px !important;
  margin-top: 12px !important;
}
.custom-file {
  width: 70%;
  border: 2px dashed #bbbbbb;
  box-shadow: unset !important;
  margin-left: -2px !important;
  .custom-file-label {
    box-shadow: unset !important;
    border: unset;
    background-color: unset !important;
    &::after {
      display: inline;
      padding: 3px 2;
      background-color: unset !important;
      color: #7297ff;
      margin-right: 36%;
    }
  }
}

.checkbox {
  margin-left: 7px !important;
}
.label {
  margin-top: 6%;
}
.logo-div-img {
  // margin-top: 15%;
}
.change_password_div {
  // margin-top: 6%;
  background-color: #e3e7f3;
  border-bottom-right-radius: 25px;
  // padding: 0 !important;
  margin: auto;
  margin-right: -3px;
  margin-left: -5px;
  // width: 666px;

  // border-radius: 3%;
}
.submit-div-password {
  margin-top: -3%;
}
.old_password {
  margin-top: 4%;
}
// #info-div {
//   padding: 0 !important;
//   // margin: auto;
// }
</style>
