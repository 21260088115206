<template>
  <div class="genralInformationComponent">
    <v-form v-model="valid" ref="form">
      <v-row v-if="coursesObject.length > 0">
        <!-- col loop-->

        <v-col
          cols="12"
          md="6"
          style="border: 2px solid #ddd; border-radius: 5px"
          v-for="(item, index) in coursesObject"
          :key="index"
        >
          <div class="remove-icon text-right">
            <v-icon
              @click="deleteCourse(index)"
              style="color: #e1e1e1; cursor: pointer"
            >
              delete
            </v-icon>
          </div>
          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Course Name") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="5">
              <v-text-field
                v-model="item.course_name"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength225
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="5">
              <v-radio-group
                v-model="item.course_date_type"
                :rules="[validationRules.required]"
                row
              >
                <v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio
                ><v-radio
                  value="gregorian"
                  :label="$i18n.t('Gregorian')"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- /item -->
          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="5">
              <v-text-field
                :id="'course_date' + index"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="item.course_date_type == 'gregorian'"
                v-model.trim="item.course_date"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                :id="'course_date_hijri' + index"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="item.course_date_type == 'hijri'"
                v-model.trim="item.course_date_hijri"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Duration") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="5">
              <v-text-field
                :rules="[validationRules.positiveFloatNumber]"
                v-model="item.course_duration"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="5">
              <v-text-field
                :rules="[
                  validationRules.required,
                  validationRules.maxLength225
                ]"
                v-model="item.course_source"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Grade") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="5">
              <v-text-field
                :rules="[
                  validationRules.required,
                  validationRules.maxLength225
                ]"
                v-model="item.course_grade"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="4">
              <p>
                <strong>{{ $t("Notes") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="5">
              <v-textarea
                :rules="[validationRules.maxLength400]"
                v-model="item.course_notes"
                solo
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>

        <!-- end Col -->
      </v-row>
    </v-form>
    <!-- add new course -->
    <v-row>
      <v-col cols="12" md="12">
        <v-btn
          class="main-color custom-btn"
          style="background-color: transparent; border: 0; box-shadow: none"
          @click="addCource()"
          ><v-icon> add_circle </v-icon> {{ $t("Add Course") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>

      <v-btn class="modal-btn-cancel" style="margin: 10px" @click="close">{{
        $t("Close")
      }}</v-btn>
      <v-btn
        class="modal-btn-save"
        style="margin: 10px"
        @click="updateCourses"
        >{{ $t("Save") }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");

import { validationMixin } from "../../../../mixins/validationMixin";
export default {
  mixins: [validationMixin],
  name: "Editcourses",
  props: ["coursesObject", "editCourseInfo"],
  data() {
    return {
      valid: false
    };
  },
  watch: {
    coursesObject: {
      handler() {
        // console.log("changed");
        this.coursesObject.forEach(item => {
          if (item.course_date != "") {
            var m = moment_hijri(item.course_date, "DD/MM/YYYY");
            item.course_date_hijri = m.format("iDD/iMM/iYYYY");
          }
          if (item.course_date_hijri != "") {
            var h = moment_hijri(item.course_date_hijri, "iDD/iMM/iYYYY");
            item.course_date = h.format("DD/MM/YYYY");
          }
        });
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.editCourseInfo = false;
      this.$emit("childToParent", false);
    },
    updateCourses() {
      if (this.valid == false) {
        this.validate();
      } else {
        axios
          .post(
            this.getApiUrl +
              "/employee/update/" +
              this.$router.currentRoute.params.id,
            { courses: this.coursesObject },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            if (response.data.status.error == false) {
              // this.changeTab(1);
              this.close();
            }
          });
      }
    },
    deleteCourse(index) {
      this.coursesObject.splice(index, 1);
    },
    addCource() {
      let coursesObject = {
        course_date_type: "hijri",
        course_name: "",
        course_date: "",
        course_date_hijri: "",
        course_duration: "",
        course_source: "",
        course_grade: "",
        course_notes: ""
      };
      this.coursesObject.push(coursesObject);
      this.activateDatePicker();
    },
    activateDatePicker() {
      let _this = this;

      $(document).ready(function() {
        _this.coursesObject.forEach((element, index) => {
          $("#course_date" + index).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

                if ($(this).attr("id") == "course_date" + index) {
                  _this.coursesObject[index].course_date = dateH;
                }
              } else {
                if ($(this).attr("id") == "course_date" + index) {
                  _this.coursesObject[index].course_date = "";
                }
              }
            }
          });

          $("#course_date_hijri" + index).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function(date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                if ($(this).attr("id") == "course_date_hijri" + index) {
                  _this.coursesObject[index].course_date_hijri = dateH;
                }

                // _this.generalInfoObj.date_of_birth_hijri = dateH;
              } else {
                if ($(this).attr("id") == "course_date_hijri" + index) {
                  _this.coursesObject[index].course_date_hijri = "";
                }
              }
            }
          });
        });
      });
    }
  },
  mounted: function() {
    this.activateDatePicker();
  }
};
</script>
