var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"genralInformationComponent"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t("Basic Salary")))])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"rules":[
            _vm.validationRules.required,
            _vm.validationRules.floatNumberNotZero,
          ],"solo":""},model:{value:(_vm.SalaryObject.basic_salary),callback:function ($$v) {_vm.$set(_vm.SalaryObject, "basic_salary", $$v)},expression:"SalaryObject.basic_salary"}})],1)],1),_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',[_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t("Allowance(s)")))])]),_c('v-autocomplete',{attrs:{"items":_vm.allowancesItems,"item-text":"name","item-value":"id","multiple":"","chips":"","rules":[_vm.validationRules.required],"solo":""},on:{"change":function($event){_vm.hideLabel = true && _vm.onSelect(_vm.SalaryObject.allowaIds)}},model:{value:(_vm.SalaryObject.allowaIds),callback:function ($$v) {_vm.$set(_vm.SalaryObject, "allowaIds", $$v)},expression:"SalaryObject.allowaIds"}})],1)],1),(_vm.SalaryObject.allowances.length > 0)?_c('div',[_c('v-row',{staticClass:"form-item"},_vm._l((_vm.SalaryObject.allowances),function(allowance,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"3"}},[_c('div',[_c('span',{staticClass:"allowance-title"},[_vm._v(_vm._s(allowance.name))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[(allowance.account_type == 'amount')?_c('v-text-field',{staticClass:"allowance-input",attrs:{"rules":[
                    _vm.validationRules.required,
                    _vm.validationRules.numericNotZero,
                    _vm.validationRules.maxLength10,
                  ]},model:{value:(allowance.value),callback:function ($$v) {_vm.$set(allowance, "value", $$v)},expression:"allowance.value"}}):_c('v-text-field',{staticClass:"allowance-input",attrs:{"rules":[
                    _vm.validationRules.required,
                    _vm.validationRules.percentage,
                  ]},model:{value:(allowance.value),callback:function ($$v) {_vm.$set(allowance, "value", $$v)},expression:"allowance.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[(allowance.account_type == 'amount')?_c('span',{staticClass:"type"},[_vm._v(_vm._s(_vm.$t("SAR")))]):_c('span',{staticClass:"type"},[_vm._v("%")])])],1)],1)])}),1)],1):_vm._e()],1),_c('v-row',[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel",staticStyle:{"margin":"10px"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c('v-btn',{staticClass:"modal-btn-save",staticStyle:{"margin":"10px"},on:{"click":_vm.updateCourses}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }