<template>
  <div>
    <div class="genralInformationComponent" v-if="!editGeneralInfo">
      <v-row>
        <!-- First Col -->
        <v-col cols="12" md="6">
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Full Name In English") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.en.name }}</p>
            </v-col>
          </v-row>
          <!-- item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Full Name In Arabic") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.ar.name }}</p>
            </v-col>
          </v-row>
          <!-- item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Email") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.email }}</p>
            </v-col>
          </v-row>
          <!-- item -->
          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Nationality") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.nationality }}</p>
            </v-col>
          </v-row>
          <!-- item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Place Of birth") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.place_of_birth }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Gender") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.gendar }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Mobile") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.mobile_number }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date Of Birth") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.date_of_birth_gregorian }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Date Of Birth Hijri") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.date_of_birth_hijri }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("ID Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_id_number }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_id_source }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_id_expiry_date_gregorian }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Date Hijri") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_id_expiry_date_hijri }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Passport Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_passport_number }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_passport_source }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Date") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_passport_expiry_date_gregorian }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Date Hijri") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_passport_expiry_date_hijri }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row v-if="generalInfoObj.citizen_social_insurance_number">
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Social Insurance") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.citizen_social_insurance_number }}</p>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>
        <!-- end Col -->

        <!-- Second Col -->
        <v-col cols="12" md="6">
          <!-- item -->
          <v-row v-if="generalInfoObj.sponsor_name">
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Sponsor Name") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.sponsor_name }}</p>
            </v-col>
          </v-row>
          <!-- item -->

          <!-- item -->
          <v-row v-if="generalInfoObj.sponsor_relationship">
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Relationship") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.sponsor_relationship }}</p>
            </v-col>
          </v-row>
          <!-- item -->

          <!-- item -->
          <v-row
            v-if="
              generalInfoObj.sponsor_relationship != 'Other' &&
              generalInfoObj.sponsor_id_number
            "
          >
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("ID Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.sponsor_id_number }}</p>
            </v-col>
          </v-row>
          <!-- item -->
          <!-- item -->
          <v-row
            v-if="
              generalInfoObj.sponsor_relationship != 'Other' &&
              generalInfoObj.sponsor_expiry_date_gregorian
            "
          >
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.sponsor_expiry_date_gregorian }}</p>
            </v-col>
          </v-row>
          <!-- item -->

          <!-- item -->
          <v-row
            v-if="
              generalInfoObj.sponsor_relationship != 'Other' &&
              generalInfoObj.sponsor_expiry_date_hijri
            "
          >
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Expiry Hijri") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.sponsor_expiry_date_hijri }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row
            v-if="
              generalInfoObj.sponsor_relationship != 'Other' &&
              generalInfoObj.sponsor_source
            "
          >
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Source") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.sponsor_source }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Country") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.address_country }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("City") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.address_city }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Street") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.address_street }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Building Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.address_building_number }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Additional Number") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.address_additional_number }}</p>
            </v-col>
          </v-row>
          <!-- /item -->

          <!-- item -->
          <v-row>
            <v-col cols="12" md="6">
              <p>
                <strong>{{ $t("Zip Code") }}:</strong>
              </p>
            </v-col>

            <v-col cols="12" md="6">
              <p>{{ generalInfoObj.address_zip_code }}</p>
            </v-col>
          </v-row>
          <!-- /item -->
        </v-col>
        <!-- end Col -->
      </v-row>
    </div>
    <editGenralInformationComponent
      :generalInfoObj="generalInfoObj"
      :editGeneralInfo="editGeneralInfo"
      v-on:childToParent="onChildClick"
      v-if="editGeneralInfo"
    />
  </div>
</template>

<script>
import editGenralInformationComponent from "../EditEmployee/generalInformationComponent";
import axios from "axios";
export default {
  name: "genralInformationComponent",
  props: ["editGeneralInfo"],
  data() {
    return {
      generalInfoObj: {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        email: "",
        nationality: "",
        nationality_id: "",
        place_of_birth: "",
        gendar: "",
        mobile_number: "",
        date_of_birth_gregorian: "",
        date_of_birth_hijri: "",
        citizen_id_number: "",
        citizen_id_source: "",
        citizen_id_expiry_date_gregorian: "",
        citizen_id_expiry_date_hijri: "",
        citizen_passport_number: "",
        citizen_passport_source: "",
        citizen_passport_expiry_date_gregorian: "",
        citizen_passport_expiry_date_hijri: "",
        citizen_social_insurance_number: "",
        sponsor_name: "",
        sponsor_relationship: "",
        sponsor_id_number: "",
        sponsor_expiry_date_gregorian: "",
        sponsor_expiry_date_hijri: "",
        sponsor_source: "",
        sponsor_job: "",
        address_country: "",
        address_city: "",
        address_street: "",
        address_building_number: "",
        address_additional_number: "",
        address_zip_code: "",
        sponsor_expiry_date_type: "",
      },
    };
  },
  components: {
    editGenralInformationComponent,
  },
  methods: {
    onChildClick(val) {
      this.getGeneralInformationData();
      this.editGeneralInfo = val;
      this.$emit("GeneralParent", false);
    },
    getGeneralInformationData() {
      axios
        .get(
          this.getApiUrl +
            "/employee/showGenralInformation/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.generalInfoObj = response.data.data;
          this.generalInfoObj = {
            en: {
              name: response.data.data.en_name,
            },
            ar: {
              name: response.data.data.ar_name,
            },
            email: response.data.data.email,
            nationality: response.data.data.nationality,
            nationality_id: response.data.data.nationality_id,
            place_of_birth: response.data.data.place_of_birth,
            gendar: response.data.data.gendar,
            mobile_number: response.data.data.mobile_number,
            date_of_birth_gregorian: response.data.data.date_of_birth_gregorian,
            date_of_birth_hijri: response.data.data.date_of_birth_hijri,
            citizen_id_number: response.data.data.citizen_id_number,
            citizen_id_source: response.data.data.citizen_id_source,
            citizen_id_expiry_date_gregorian:
              response.data.data.citizen_id_expiry_date_gregorian,
            citizen_id_expiry_date_hijri:
              response.data.data.citizen_id_expiry_date_hijri,
            citizen_passport_number: response.data.data.citizen_passport_number,
            citizen_passport_source: response.data.data.citizen_passport_source,
            citizen_passport_expiry_date_gregorian:
              response.data.data.citizen_passport_expiry_date_gregorian,
            citizen_passport_expiry_date_hijri:
              response.data.data.citizen_passport_expiry_date_hijri,
            citizen_social_insurance_number:
              response.data.data.citizen_social_insurance_number,
            sponsor_name: response.data.data.sponsor_name,
            sponsor_relationship: response.data.data.sponsor_relationship,
            sponsor_id_number: response.data.data.sponsor_id_number,
            sponsor_expiry_date_gregorian:
              response.data.data.sponsor_expiry_date_gregorian,
            sponsor_expiry_date_hijri:
              response.data.data.sponsor_expiry_date_hijri,
            sponsor_source: response.data.data.sponsor_source,
            sponsor_job: response.data.data.sponsor_job,
            address_country: response.data.data.address_country,
            address_city: response.data.data.address_city,
            address_street: response.data.data.address_street,
            address_building_number: response.data.data.address_building_number,
            address_additional_number:
              response.data.data.address_additional_number,
            address_zip_code: response.data.data.address_zip_code,
            sponsor_expiry_date_type:
              response.data.data.sponsor_expiry_date_type,
            sponsor_notes: response.data.data.sponsor_notes,
            mobile_country_code_id: response.data.data.mobile_country_code_id,
            mobile: response.data.data.mobile,
            date_of_birth_type: "hijri",
            citizen_id_expiry_date_type: "hijri",
            citizen_passport_expiry_date_type: "hijri",
            address_country_id: response.data.data.address_country_id,
          };
        });
    },
  },
  mounted: function () {
    this.getGeneralInformationData();
  },
};
</script>
