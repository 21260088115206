<template>
  <div>
    <div class="create_editMode" v-if="editUserAccount">
      <v-row>
        <v-col class="py-0">
          <v-radio-group v-model="userType" row>
            <v-radio
              v-if="!newUserAccount.is_user"
              :label="$i18n.t('Create new user')"
              value="new"
            ></v-radio>
            <v-radio
              v-else
              :label="$i18n.t('Update User')"
              value="new"
            ></v-radio>
            <v-radio
              :label="$i18n.t('Existing user')"
              value="existing"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" v-if="userType == 'new'">
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("First Name") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-text-field
                  v-model.trim="newUserAccount.first_name"
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                  dense
                ></v-text-field>

                <div v-if="validation_errors.first_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.first_name"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Last Name") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-text-field
                  v-model.trim="newUserAccount.last_name"
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                  dense
                ></v-text-field>
                <div v-if="validation_errors.last_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.last_name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Arabic Name") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-text-field
                  v-model="newUserAccount.ar_name"
                  :rules="[
                    validationRules.maxLength50,
                    CheckArabicCharactersOnly(newUserAccount.ar_name),
                  ]"
                  solo
                  dense
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.ar_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.ar_name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Country") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-autocomplete
                  :items="nationalities"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  v-model="newUserAccount.country_id"
                  :rules="[validationRules.required]"
                  :autocomplete="false"
                  solo
                  dense
                ></v-autocomplete>
                <div v-if="validation_errors.country_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.country_id"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Mobile") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-row>
                  <v-col cols="5" sm="5" class="pa-0">
                    <v-autocomplete
                      :items="Codes"
                      required
                      @change="hideLabel = true"
                      item-text="title"
                      item-value="value"
                      v-model="newUserAccount.country_code_id"
                      solo
                      dense
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- <img src="../../assets/flags/AF.png" />
                                    {{ data.title }}-->

                        <v-flex xs3>
                          <v-avatar size="25">
                            <img :src="data.item.icon" />
                          </v-avatar>
                        </v-flex>
                        <v-flex class="ml-3">{{ data.item.title }}</v-flex>
                      </template>
                      <template slot="item" slot-scope="data">
                        <v-list-item-avatar
                          style="width: 20px; min-width: 20px; height: 20px"
                        >
                          <img :src="data.item.icon" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <div v-if="validation_errors.country_code_id">
                      <p
                        class="red--text"
                        v-for="(
                          error, index
                        ) in validation_errors.country_code_id"
                        :key="index"
                      >
                        {{ error }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="7" sm="7" class="pa-0">
                    <v-row>
                      <v-col class="pa-0">
                        <v-text-field
                          v-model.trim="newUserAccount.mobile"
                          @focus="onFocus()"
                          :rules="[
                            validationRules.required,
                            mobileRule(
                              newUserAccount.country_code_id,
                              newUserAccount.mobile
                            ),
                          ]"
                          solo
                          dense
                        ></v-text-field>
                        <div v-if="validation_errors.mobile">
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors.mobile"
                            :key="index"
                            @focus="onFocus()"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Email") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-text-field
                  v-model.trim="newUserAccount.email"
                  @focus="onFocus()"
                  :rules="[validationRules.required, validationRules.email]"
                  solo
                  dense
                ></v-text-field>
                <div v-if="validation_errors.email">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.email"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="!newUserAccount.is_user">
          <v-col>
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Password") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-text-field
                  v-model.trim="newUserAccount.password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @focus="onFocus()"
                  id="testing-code"
                  :rules="[validationRules.required]"
                  @click:append="show = !show"
                  solo
                  dense
                ></v-text-field>
                <div v-if="validation_errors.password">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.password"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Date Of Birth") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-text-field
                  id="gregorian"
                  @focus="onFocus()"
                  autocomplete="off"
                  v-model.lazy="newUserAccount.date_of_birth"
                  solo
                  dense
                  @keydown.prevent
                  @paste.prevent
                  @drop.prevent
                ></v-text-field>
                <div
                  v-if="validation_errors.date_of_birth"
                  class="vertical-space"
                >
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.date_of_birth"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Gender") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-row justify="start">
                  <v-checkbox
                    class="mx-2"
                    :label="$i18n.t('Male')"
                    id="male"
                    value="Male"
                    v-model.trim="newUserAccount.gender"
                    @change="onFocus()"
                  ></v-checkbox>
                  <v-checkbox
                    class="mx-2"
                    :label="$i18n.t('Female')"
                    id="female"
                    value="Female"
                    v-model.trim="newUserAccount.gender"
                    @change="onFocus()"
                  ></v-checkbox>
                </v-row>
                <div v-if="error_gender">
                  <p class="red--text">{{ $t("This field is required") }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="4" class="pa-0">
                <label
                  style="display: block; text-align: center; padding-top: 8px"
                  >{{ $t("Role") }}</label
                >
              </v-col>
              <v-col cols="8" class="pa-0">
                <v-autocomplete
                  :items="roles"
                  @change="hideLabel = true"
                  item-text="display_name"
                  item-value="id"
                  multiple
                  v-model="newUserAccount.roles_ids"
                  :rules="[checkMultiSelect(newUserAccount.roles_ids)]"
                  solo
                ></v-autocomplete>
                <div v-if="validation_errors.roles_ids">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.roles_ids"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            <!-- @click="close" -->
            <v-btn
              class="modal-btn-cancel mr-4"
              v-if="newUserAccount.user_id"
              @click="close"
              >{{ $t("Close") }}</v-btn
            >
            <!-- @click="save" :loading="loading" :disabled="loading" -->
            <v-btn class="modal-btn-save" @click="createNewUser()">{{
              $t("Save")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="userType == 'existing'">
        <v-form ref="form" v-model="valid" style="width: 100%">
          <v-col cols="12">
            <label for="select role">{{ $t("Select User") }}</label>
            <v-autocomplete
              :items="users"
              @change="hideLabel = true"
              item-text="name"
              item-value="id"
              v-model="assignToUserId"
              :rules="[validationRules.required]"
              solo
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" class="text-right">
            <!-- @click="close" -->
            <v-btn class="modal-btn-cancel mr-4" @click="close">{{
              $t("Close")
            }}</v-btn>
            <!-- @click="save" :loading="loading" :disabled="loading" -->
            <v-btn class="modal-btn-save" @click="assignUserToEmployee()">{{
              $t("Save")
            }}</v-btn>
          </v-col>
        </v-form>
      </v-row>
    </div>
    <!-- view Mode -->
    <div class="view_mode" v-if="!editUserAccount">
      <v-row>
        <h2
          class="main-color"
          style="font-size: 18px; font-weight: 600; margin-bottom: 0"
        >
          {{ $t("Profile Data") }}
        </h2>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" md="4">{{ $t("First Name") }}</v-col>
            <v-col cols="12" md="4">{{ newUserAccount.first_name }}</v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">{{ $t("Last Name") }}</v-col>
            <v-col cols="12" md="4">{{ newUserAccount.last_name }}</v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">{{ $t("Country") }}</v-col>
            <v-col cols="12" md="4">{{ newUserAccount.country_name }}</v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">{{ $t("Gender") }}</v-col>
            <v-col cols="12" md="4">{{ newUserAccount.gender }}</v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">{{ $t("Date Of Birth") }}</v-col>
            <v-col cols="12" md="4">{{ newUserAccount.date_of_birth }}</v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">{{ $t("Hiji Date Of Birth") }}</v-col>
            <v-col cols="12" md="4">{{
              newUserAccount.date_of_birth_hijri
            }}</v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <h2
          class="main-color"
          style="font-size: 18px; font-weight: 600; margin-bottom: 0"
        >
          {{ $t("Login Credentials") }}
        </h2>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" md="4">{{ $t("Email") }}</v-col>
            <v-col cols="12" md="4">{{ newUserAccount.email }}</v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">{{ $t("Mobile") }}</v-col>
            <v-col cols="12" md="4">{{
              newUserAccount.mobile_full_number
            }}</v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">{{ $t("Role") }}</v-col>
            <v-col cols="12" md="4">{{ newUserAccount.roles_names }}</v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import ACL from "../../acl";
import { validationMixin } from "../../../../mixins/validationMixin";

export default {
  name: "CreateUserAccount",
  props: ["editUserAccount"],
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      userType: "new",
      error_gender: false,
      validation_errors: {},
      newUserAccount: {
        is_user: "",
        user_id: "",
        first_name: "",
        last_name: "",
        ar_name: "",
        country_id: "",
        country_code_id: "",
        mobile: "",
        email: "",
        password: "",
        date_of_birth: "",
        gender: "",
        roles_ids: "",
      },
      assignToUserId: "",
      users: [],
      nationalities: [],
      Codes: [],
      passwordType: "password",
      show: false,
      roles: [],
      selectedRole: "",
    };
  },
  watch: {
    "newUserAccount.is_user": {
      handler() {
        if (!this.newUserAccount.is_user) {
          this.$emit("showIconEdit", true);
        }
      },
      deep: true,
    },
  },
  methods: {
    assignUserToEmployee() {
      if (this.valid == false) {
        this.validate();
      } else {
        axios
          .post(
            this.getApiUrl +
              "/employee/assignUserToEmployee/" +
              this.$router.currentRoute.params.id,
            { user_id: this.assignToUserId },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.$emit("showIconEdit", false);
              this.getUserDate();
            }
          });
      }
    },
    createNewUser() {
      if (this.valid == false) {
        this.validate();
      } else {
        if (this.newUserAccount.gender == "") {
          this.error_gender = true;
        } else {
          axios
            .post(
              this.getApiUrl +
                "/employee/createNewUser/" +
                this.$router.currentRoute.params.id,
              this.newUserAccount,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                this.$emit("showIconEdit", false);
                this.getUserDate();
                // this.this.close();
              }
            });
        }
      }
    },
    onFocus() {
      this.validation_errors = {
        first_name: "",
        last_name: "",
        ar_name: "",
        email: "",
        password: "",
        mobile: "",
        date_of_birth: "",
        country_id: "",
        roles_ids: "",
        country_code_id: "",
      };
    },

    initiateDataPicker() {
      var _this = this;
      $(document).ready(function () {
        $("#gregorian").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.newUserAccount.date_of_birth = dateH;
            } else {
              _this.studentObject.date_of_birth = "";
            }
          },
        });
      });
    },
    getCodes() {
      axios.get(this.getApiUrl + "/getCodes").then((response) => {
        this.Codes = response.data.data;
      });
    },
    getCountries() {
      axios.get(this.getApiUrl + "/getCountries").then((response) => {
        this.nationalities = response.data.data;
      });
    },

    allRoles() {
      axios.get(this.getApiUrl + "/allRoles").then((response) => {
        this.roles = response.data.data;
      });
    },
    getUsers() {
      axios
        .get(
          this.getApiUrl +
            "/employee/allUsersNotEmployee/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.users = response.data.data;
        });
    },

    getUserDate() {
      axios
        .get(
          this.getApiUrl +
            "/employee/getEmployeeUserData/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.newUserAccount = response.data.data;
          this.assignToUserId = response.data.data.user_id;
        });
    },
    close() {
      this.$emit("showIconEdit", false);
      // this.editUserAccount = false;
    },
  },
  mounted() {
    this.initiateDataPicker();
    this.getCodes();
    this.getCountries();
    this.allRoles();
    this.getUsers();
    this.getUserDate();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/_variables.scss";
// @import "../../styles/_modals.scss";
</style>