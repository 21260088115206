<template>
  <div class="genralInformationComponent">
    <div v-if="!editContractInfo">
      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Date Signed") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.contract_date_signed }}</p>
        </v-col>
      </v-row>
      <!-- /item -->

      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Date Signed Hijri") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.contract_date_signed_hijri }}</p>
        </v-col>
      </v-row>
      <!-- /item -->

      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Start Date") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.contract_start_date }}</p>
        </v-col>
      </v-row>
      <!-- /item -->
      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Start Date Hijri") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.contract_start_date_hijri }}</p>
        </v-col>
      </v-row>
      <!-- /item -->

      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("End Date") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.contract_end_date }}</p>
        </v-col>
      </v-row>
      <!-- /item -->

      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("End Date Hijri") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.contract_end_date_hijri }}</p>
        </v-col>
      </v-row>
      <!-- /item -->

      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Shift") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.shift }}</p>
        </v-col>
      </v-row>
      <!-- /item -->

      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Allow Mobile Check In") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>
            <span v-if="ContractObject.allow_mobile_check_in">{{
              $t("Yes")
            }}</span>
            <span v-else>{{ $t("No") }}</span>
          </p>
        </v-col>
      </v-row>
      <!-- /item -->

      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Probation Duration") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.duration }} {{ $t("Days") }}</p>
        </v-col>
      </v-row>
      <!-- /item -->
      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Probation End Date") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.contract_probationdate_end_data }}</p>
        </v-col>
      </v-row>
      <!-- /item -->

      <!-- item -->
      <v-row>
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Probation End Date Hijri") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.contract_probationdate_end_data_hijri }}</p>
        </v-col>
      </v-row>
      <!-- /item -->
      <!-- item -->
      <v-row v-if="ContractObject.is_extend">
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Probation Extend to Date") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.extend_to }}</p>
        </v-col>
      </v-row>
      <!-- /item -->
      <v-row v-if="ContractObject.is_extend">
        <v-col cols="12" md="4">
          <p>
            <strong>{{ $t("Probation Extend Duration") }}:</strong>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <p>{{ ContractObject.extend_duration }} {{ $t("Days") }}</p>
        </v-col>
      </v-row>
      <!-- /item -->
    </div>
    <ContractComponent
      :ContractObject="ContractObject"
      v-on:childToParent="onChildClick"
      v-if="editContractInfo"
    />
  </div>
</template>

<script>
import axios from "axios";
import ContractComponent from "../EditEmployee/ContractComponent";
export default {
  name: "contract",
  props: ["editContractInfo"],
  data() {
    return {
      ContractObject: {
        // shift_id: "",
        // shift: "",
        // contract_date_signed: "",
        // contract_date_signed_hijri: "",
        // contract_start_date: "",
        // contract_start_date_hijri: "",
        // contract_end_date: "",
        // contract_end_date_hijri: "",
        // duration: "",
        // contract_probationdate_end_data: "",
        // contract_probationdate_end_data_hijri: "",
        // contract_end_date_hijri: "",
        // allow_mobile_check_in: "",
      },
    };
  },
  components: {
    ContractComponent,
  },
  methods: {
    onChildClick(val) {
      this.getData();
      // this.editJobInfo = val;
      this.$emit("ContractParent", val);
    },
    getData() {
      axios
        .get(
          this.getApiUrl +
            "/employee/getContract/" +
            this.$router.currentRoute.params.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.ContractObject = response.data.data;
        });
    },
  },
  mounted: function () {
    this.getData();
  },
};
</script>
